import MainLayout from "@/layout/MainLayout";
import "@/pages/App.css";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "loa" });
  });
  return (
    <MainLayout header>
      {" "}
      <div className="App">hi</div>
    </MainLayout>
  );
}

export default App;
